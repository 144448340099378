<template>
  <div class="news">
    <!-- p1-->
    <section class="news-section-1">
      <img class="sec-1-img-1 animate__animated animate__fadeInDown" src="../../assets/images/words@2x.png" alt="">
      <img class="sec-1-img-2 animate__animated animate__fadeInDown" src="../../assets/images/news-pic@2x.png" alt="">
      <div class="sec-1-title animate__animated animate__fadeInDown">{{ $t('news.msg_1') }}</div>
      <!-- 新闻列表 -->
      <div class="news-list" v-show="showList.length">
        <div class="news-item" v-for="(item, index) in showList" :key="index" @click="clickNews(item)">
          <div class="news-item-title"><span class="news-item-title-icon">//</span>{{ item.title }}</div>
          <div class="news-item-time">{{ item.createTime | formatTime }}</div>
        </div>
      </div>
      <!-- 新闻按钮 -->
      <div class="news-button">
        <div
          :class="['news-button-item','button-prev', searchData.pageNum === 1 ? 'button-disable' : 'button-able']"
          @click="prevClick"
        >
          &lt;&nbsp;{{ $t('news.msg_2') }}
        </div>
        <div
          :class="['news-button-item','button-next', searchData.pageNum >= totalPage ? 'button-disable' : 'button-able']"
          @click="nextClick"
        >
          {{ $t('news.msg_3') }}&nbsp;&gt;
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import * as api from '../../common/api'
import dayjs from 'dayjs'

export default {
  data () {
    return {
      showList: [],
      searchData: {
        show: true,
        pageNum: 1,
        pageSize: 10,
      },
      flipInfo: {
        totalCount: 0,
        pageSize: 7,
        pageNum: 1,
      },
      totalPage: 0,
    }
  },
  filters: {
    formatTime: value => {
      if (!value) return ''
      return dayjs(value).format('YYYY年MM月DD日')
    },
  },
  mounted() {
    this.getNews()
    this.showList = this.newsList.slice(0, 7)
  },
  methods: {
    async getNews () {
      const result = await api.query(this.searchData)
      const { data, flipInfo } = result
      this.showList = [...data]
      this.flipInfo = flipInfo
      const a = parseInt(flipInfo.totalCount / this.searchData.pageSize)
      const b = flipInfo % this.searchData.pageSize
      this.totalPage = b ? a + 1 : a
    },
    prevClick () {
      this.searchData.pageNum -= 1
      this.getNews()
    },
    nextClick () {
      this.searchData.pageNum += 1
      this.getNews()
    },
    clickNews (news) {
      this.$router.push({
        path: `/detail?id=${news.uid}`,
      })
    },
  },
}
</script>

<style lang="less" scope>
// 大屏样式
@media (min-width: 1001px) {
  .news {
    // p1 css
    .news-section-1 {
      height: 11.6rem;
      position: relative;

      .sec-1-img-1 {
        width: .98rem;
        height: 1.16rem;
        padding-left: 1.51rem;
        padding-top: .15rem;
      }

      .sec-1-img-2 {
        width: 5.4rem;
        height: 8.25rem;
        position: absolute;
        top: 1.5rem;
        left: 1.5rem;
      }

      .sec-1-title {
        font-size: 1rem;
        color: #F2F3F4;
        position: absolute;
        left: 7.64rem;
        top: 1.79rem;
      }

      .news-list {
        width: 10.5rem;
        height: 5.62rem;
        position: absolute;
        left: 7.2rem;
        top: 3.36rem;
        border-top: 1px solid #006da0;

        .news-item:hover {
          color: #006da0;
          cursor: pointer;
        }

        .news-item {
          border-left: 1px solid #006da0;
          border-right: 1px solid #006da0;
          border-bottom: 1px solid #006da0;
          height: .8rem;
          line-height: .8rem;
          color: #fff;
          font-size: .16rem;
          display: flex;
          flex-direction: row;
          padding-left: .53rem;
          padding-right: .74rem;

          .news-item-title {
            width: 7.58rem;

            .news-item-title-icon {
              padding-right: .2rem;
            }
          }

          .news-item.time {
            flex: 1;
          }
        }
      }

      // news button
      .news-button {
        width: 10.5rem;
        height: .38rem;
        position: absolute;
        left: 7.18rem;
        top: 9.4rem;

        .news-button-item {
          width: 1.2rem;
          height: .38rem;
          text-align: center;
          font-size: .16rem;
          line-height: .38rem;
        }

        .button-prev {
          float: left;
        }

        .button-next {
          float: right;
        }

        .button-able {
          background-color: #006b9c;
          color: #fff;
        }

        .button-disable {
          background-color: #262c39;
          color: #C0C2C4;
        }
      }
    }
  }
}
// 小屏样式
@media (max-width: 1000px) {
  .news {
    // p1 css
    .news-section-1 {
      position: relative;
      .sec-1-img-1 {
        width: .98rem;
        height: 1.16rem;
        position: absolute;
        top: .12rem;
        left: .4rem;
      }

      .sec-1-img-2 {
        margin-top: 1.62rem;
        padding-left: .4rem;
        width: 5.4rem;
        height: 8.25rem;
      }

      .sec-1-title {
        font-size: 1rem;
        color: #F2F3F4;
        position: absolute;
        right: .74rem;
        top: .26rem;
      }

      .news-list {
        width: 6.7rem;
        margin: 1rem auto 0 auto;
        border-top: 1px solid #006da0;

        .news-item:hover {
          color: #006da0;
          cursor: pointer;
        }

        .news-item {
          border-left: 1px solid #006da0;
          border-right: 1px solid #006da0;
          border-bottom: 1px solid #006da0;
          height: .8rem;
          line-height: .8rem;
          color: #fff;
          font-size: .16rem;
          display: flex;
          flex-direction: row;
          padding-left: .2rem;
          padding-right: .2rem;

          .news-item-title {
            width: 4.4rem;
            overflow:hidden;
            text-overflow:ellipsis; /* 加省略号 */
            white-space:nowrap; /* 强制不换行 */

            .news-item-title-icon {
              padding-right: .2rem;
            }
          }

          .news-item-time {
            text-align: right;
            flex: 1;
          }
        }
      }

      // news button
      .news-button {
        width: 6.7rem;
        margin: .6rem auto 1rem auto;
        height: .38rem;

        .news-button-item {
          width: 1.2rem;
          height: .38rem;
          text-align: center;
          font-size: .16rem;
          line-height: .38rem;
        }

        .button-prev {
          float: left;
        }

        .button-next {
          float: right;
        }

        .button-able {
          background-color: #006b9c;
          color: #fff;
        }

        .button-disable {
          background-color: #262c39;
          color: #C0C2C4;
        }
      }
    }
  }
}
</style>